import moment from "moment-timezone";
import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? moment(date).tz("UTC").format(fm) : '';
}

export function headerDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? moment(date).tz("Asia/Seoul").format(fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? moment(date).tz("UTC").format(fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatTime(seconds = 0) {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
}
