import { Box, Divider, CircularProgress, LinearProgress, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { differenceInSeconds, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDeepCompareEffect, useInterval } from 'react-use';
import { StatusChips } from '../../../components/select/StatusChips';
import {
  ContentText,
  StartButton,
  SubTitle,
  TitleText,
} from '../../../components/style/CommonStyle';
import { ArrowIcon, PlayIcon } from '../../../layouts/dashboard/nav/IconButton';
import {
  LocationList,
  ProcessingStatusList,
  StatusList,
} from '../../../utils/enum';
import { fDate, formatTime } from '../../../utils/formatTime';
import { EmptyDiv } from './EmptyDiv';
import StopVideoEnhancementButton from './StopVideoEnhancementButton';
import { formatBytes } from '../../../utils/helpers';

export default function RunningProcessCard({ queueFile }) {
  const fileList = _.get(queueFile, 'data') || [];
  const processingVideo = _.filter(fileList, f =>
    [StatusList.PROCESSING, StatusList.DECODING, StatusList.PENDING].includes(f.status),
  );
  const processingInfo = processingVideo[0];
  const processingVideoInfo = processingInfo?.video;
  const isNotProcessing = _.isEmpty(processingVideo);
  const thumbnails = processingInfo?.thumbnail_list || [];

  const videoOutput = processingInfo?.video_outputs || [];
  const startTime = parseISO(processingInfo?.start_time);

  const [thumbnailIndex, setThumbnailIndex] = useState(0);

  const interval = getInterval();
  function getInterval() {
    if (_.isEmpty(videoOutput)) {
      return null;
    }
    // TODO Hotfix code to NHN
    const dateStartTime = new Date(startTime)
    const processStartTime = dateStartTime.getTime() + (dateStartTime.getTimezoneOffset() * 60 * 1000)
    
    const lastVideoOutput = videoOutput[videoOutput?.length - 1];
    const expectedCompleteTime = parseISO(
      lastVideoOutput?.expected_complete_time,
    );
    const duration = differenceInSeconds(expectedCompleteTime, processStartTime);
    return (duration / thumbnails.length) * 1000;
  }

  useDeepCompareEffect(() => {
    if (_.isEmpty(thumbnails)) {
      setThumbnailIndex(0);
    } else {
      if (_.isEmpty(videoOutput)) {
        setThumbnailIndex(0);
      } else {
        // TODO Hotfix code to NHN
        const dateStartTime = new Date(startTime)
        const processStartTime = dateStartTime.getTime() + (dateStartTime.getTimezoneOffset() * 60 * 1000)

        const lastVideoOutput = videoOutput[videoOutput?.length - 1];
        const expectedCompleteTime = parseISO(
          lastVideoOutput?.expected_complete_time,
        );
        const duration = differenceInSeconds(expectedCompleteTime, processStartTime);
        
        // Update thumbnail index
        const now = new Date()
        const utc = now.getTime() - (now.getTimezoneOffset() * 60 * 1000)
        const diff_now = differenceInSeconds(utc, startTime);
        let current_thumbnail_index = Math.round(diff_now / duration * 100)
        if (current_thumbnail_index < 0) {
          current_thumbnail_index = 0
        } else if (current_thumbnail_index > thumbnails.length ) {
          current_thumbnail_index = current_thumbnail_index % thumbnails.length
        }
        setThumbnailIndex(current_thumbnail_index)
      }
    }
    
  }, [thumbnails, videoOutput]);

  useInterval(() => {
    setThumbnailIndex(prevValue => prevValue + 1);
  }, interval);

  function getCurrentThumbnail() {
    return `/upload_videos/${thumbnails[thumbnailIndex % thumbnails.length]}`;
  }

  return (
    <Stack
      flex={1}
      sx={{ borderRight: '2px solid #1E1E1E' }}
      p={3}
      spacing={1.5}
    >
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <TitleText>RUNNING PROCESS</TitleText>
        {isNotProcessing ? (
          <StartRunningButton fileList={fileList} />
        ) : (
          <StopVideoEnhancementButton
            processingVideo={processingVideo}
            location={LocationList.RUNNING_PROCESS}
          />
        )}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        bgcolor="#3A3A3A"
        px={1.5}
        py={1}
        alignItems="center"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <ArrowIcon />
          <ContentText>{processingVideoInfo?.name || 'None'}</ContentText>
        </Stack>

        <Stack direction="row" spacing={1}>
          <StatusChips status={processingInfo?.status} />
        </Stack>
      </Stack>
      <Stack alignItems="stretch" height="100%">
        {
          _.isEmpty(thumbnails) ? (
            processingInfo !== undefined ? (
              processingInfo.status === StatusList.DECODING ? (
                <Stack 
                  direction="row" 
                  alignItems="center" 
                  justifyContent="center"
                  bgcolor="#3A3A3A"
                  height="100%">
                  <CircularProgress
                    style={{width: 100, height: 100}}
                  />
                </Stack>
              ) : (
                <EmptyDiv content="No video is currently being processed." />  
              ) 
            ) : (
              <EmptyDiv content="No video is currently being processed." />
            )
          ) : (            
            <Stack direction="row" spacing={1} height="100%">
              <Box
                // bgcolor="#3A3A3A"
                maxHeight="48vh"
                alignItems="center"
                justifyContent="center"
                component="img"
                alt="processing"
                src={getCurrentThumbnail()}
                style={{ margin: 'auto' }}
              />
            </Stack>   
          )
        }
      </Stack>
      <Stack direction="row" alignItems="stretch" height="100%">
        <InputFileInfo processingVideo={processingVideo} />
        <ProcessingInfo processingVideo={processingVideo} />
      </Stack>
    </Stack>
  );
}

function InputFileInfo({ processingVideo }) {
  const inputFileInfo = processingVideo[0]?.video;

  return (
    <Stack
      flex={1}
      bgcolor="#3A3A3A"
      borderRight="2px solid #2A2B2C"
      p={3}
      spacing={3}
    >
      <TitleText fontSize="0.9rem">INPUT FILE INFO</TitleText>
      <Box sx={{ marginTop: '10px !important' }}>
        <Divider sx={{ border: '1px solid #8C8C8C' }} />
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>Format</SubTitle>
        <ContentText>{inputFileInfo?.format || ''}</ContentText>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>Codec</SubTitle>
        <ContentText>{inputFileInfo?.codec || ''}</ContentText>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>Resolution</SubTitle>
        <ContentText>
          {inputFileInfo?.width || 0}x{inputFileInfo?.height || 0}
        </ContentText>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>File Length</SubTitle>
        <ContentText>{formatTime(inputFileInfo?.duration)}</ContentText>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>File size</SubTitle>
        <ContentText>{formatBytes(inputFileInfo?.filesize)}</ContentText>
      </Stack>
    </Stack>
  );
}

function ProcessingInfo({ processingVideo }) {
  const [passedSeconds, setPassedSeconds] = useState(0);
  const [persentageOfComplete, setPersentageOfComplete] = useState(0);

  const processingFileInfo = processingVideo[0];
  const videoOuputList = processingVideo[0]?.video_outputs;
  const LengthOfList = videoOuputList?.length || 1;
  const countCompleteStatus =
    _.countBy(videoOuputList, 'status')[ProcessingStatusList.COMPLETED] || 0;
  useEffect(() => {
    if (!_.isEmpty(processingFileInfo)) {
      setPassedSeconds(0);
      setPersentageOfComplete(0);
      if (processingFileInfo.start_time !== null) {
        const expected_duration = processingFileInfo.video_outputs[0].expected_duration
        const intervalId = setInterval(() => {
          const now = new Date()
          const utc = now.getTime() - (now.getTimezoneOffset() * 60 * 1000)
          const passedSeconds = differenceInSeconds(
            new Date(utc),
            new Date(processingFileInfo.start_time),
          );
          const percentageVal = 100 - Math.floor((expected_duration-passedSeconds)/expected_duration*100)
          const percentage = percentageVal >= 100 ? 100 : percentageVal
          setPersentageOfComplete(percentage);
          setPassedSeconds(passedSeconds);
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line
  }, [processingFileInfo?.start_time]);

  const formattedProcessingTime =
    _.isEmpty(processingFileInfo) || _.isEmpty(processingFileInfo.start_time)
      ? 'Press the START button'
      : formatTime(passedSeconds);

  return (
    <Stack flex={1} bgcolor="#3A3A3A" p={3} spacing={3}>
      <TitleText fontSize="0.9rem">PROCESSING INFO</TitleText>
      <Box sx={{ marginTop: '10px !important' }}>
        <Divider sx={{ border: '1px solid #8C8C8C' }} />
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>Option</SubTitle>
        <ContentText>{processingFileInfo?.option || ''}</ContentText>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>Start time</SubTitle>
        <ContentText>
          {fDate(processingFileInfo?.start_time, 'yyyy.MM.DD HH:mm:ss') ||
            'Press the START button'}
        </ContentText>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>Processing time</SubTitle>
        <ContentText>{formattedProcessingTime}</ContentText>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <SubTitle>Process</SubTitle>
        {_.isEmpty(videoOuputList) ? (
          <ContentText>
            0 <span>%</span>
          </ContentText>
        ) : (
          <ContentText>
            {persentageOfComplete || 0} <span>%</span>
          </ContentText>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <ContentText sx={{ color: '#8C8C8C' }}>
          {processingFileInfo?.video?.name || 'None'}
        </ContentText>
      </Stack>
      <LinearDeterminate progress={persentageOfComplete || 0} />
    </Stack>
  );
}

export function LinearDeterminate({ progress }) {
  return (
    <Box sx={{ width: '100%', marginTop: '4px !important' }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ backgroundColor: '#565656' }}
      />
    </Box>
  );
}

function StartRunningButton({ fileList }) {
  const isNotSelected = _.isEmpty(fileList);
  const mutationResult = useMutation<AxiosResponse, AxiosError, any>({
    mutationFn: async payload => {
      const res = await axios.post(`/api/enqueue-video/start/`, payload);
      return res;
    },
  });
  const { mutate, isLoading: isMutating } = mutationResult;
  const vieoIdesArray = _.map(fileList, v => v.id);

  const payload = {
    video_ids: vieoIdesArray,
  };

  return (
    <>
      <StartButton
        startIcon={<PlayIcon />}
        loading={isMutating}
        disabled={isNotSelected}
        onClick={() => mutate(payload)}
      >
        START
      </StartButton>
    </>
  );
}
