import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { ApiFailModal } from '../../../../components/modal/ApiResultHandler';
import { DeleteButton } from '../../../../components/style/CommonStyle';
import { DeleteIcon } from '../../../../layouts/dashboard/nav/IconButton';

export function RemoveCompletedVideoButton({ selectTable, setSelectedRows }) {
  const isNotSelected = _.isEmpty(selectTable);
  const mutationResult = useMutation<AxiosResponse, AxiosError, any>({
    mutationFn: async payload => {
      const res = await axios.delete(`/api/completed-video/`, {
        data: payload,
      });
      return res;
    },
    onSuccess() {
      setSelectedRows([]);
    },
  });
  const { mutate, isLoading: isMutating } = mutationResult;
  const vieoIdesArray = _.keys(selectTable);

  const payload = {
    video_ids: vieoIdesArray,
  };

  return (
    <>
      <DeleteButton
        loading={isMutating}
        startIcon={<DeleteIcon />}
        disabled={isNotSelected}
        onClick={() => mutate(payload)}
      >
        REMOVE
      </DeleteButton>

      <ApiFailModal
        apiResult={mutationResult}
        failTitle="Request failed"
        failContent="An issue occurred. Please try again."
      />
    </>
  );
}
