import { IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { differenceInMilliseconds, format } from 'date-fns';
import _ from 'lodash';
import MaterialReactTable from 'material-react-table';
import { useMemo, useRef, useState } from 'react';
import { DetailedSearch } from '../../../../components/search-input/DetailedSearchBar';
import {
  PrettyScrollbarTableWrap,
  TitleText,
} from '../../../../components/style/CommonStyle';
import { DownloadIcon } from '../../../../layouts/dashboard/nav/IconButton';
import { fDate } from '../../../../utils/formatTime';
import { EmptyDiv } from '../EmptyDiv';
import { DownloadVideoButton } from './DownloadVideoButton';
import { RemoveCompletedVideoButton } from './RemoveCompeletedVideoButton';

export default function CompleteListCard({
  initaillyLoaded,
  completeFile,
  socket,
}) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const videoFiles = useMemo(() => {
    const fileList = _.get(completeFile, 'data') || [];
    return fileList.map(item => ({
      id: _.get(item, 'id'),
      fileName: _.get(item, 'name', '-'),
      startTime: _.get(item, 'start_time', '-'),
      endTime: _.get(item, 'completed_time', '-'),
      videoPath: _.get(item, 'result_video_path', '-'),
    }));
  }, [completeFile]);

  const fileList = _.get(completeFile, 'data') || [];
  const totalDBRowCount = fileList.length;

  function refreshWithSearchKeyword(search) {
    socket?.send(
      JSON.stringify({
        message: 'COMPLETED_VIDEO_LIST',
        search_keyword: search,
      }),
    );
  }

  return (
    <Stack flex={1} p={3} spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TitleText>COMPLETE LIST</TitleText>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <DetailedSearch
            onKeyDown={(e, search) => {
              if (e.key === 'Enter') {
                refreshWithSearchKeyword(search);
              }
            }}
            onClick={(e, search) => {
              refreshWithSearchKeyword(search);
            }}
          />
          <DownloadVideoButton selectTable={selectedRows} fileList={fileList} />
          <RemoveCompletedVideoButton
            selectTable={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Stack>
      </Stack>
      <Stack height="100%">
        {totalDBRowCount === 0 ? (
          <EmptyDiv />
        ) : (
          <>
            <CompleteVideoFileTable
              videoFiles={videoFiles}
              isFetching={!initaillyLoaded}
              isLoading={!initaillyLoaded}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}

function CompleteVideoFileTable({
  videoFiles,
  isFetching,
  isLoading,
  selectedRows,
  setSelectedRows,
}: any) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fileName',
        header: 'File name',
        Cell: ({ cell }: any) => (
          <Typography sx={{ color: '#fff', fontSize: '0.9rem' }}>
            {cell.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'startTime',
        header: 'Start time',
        Cell: ({ cell }: any) => (
          <>{fDate(cell.getValue(), 'yyyy.MM.DD HH:mm:ss')}</>
        ),
      },
      {
        accessorKey: 'endTime',
        header: 'End time',
        Cell: ({ cell }: any) => (
          <>{fDate(cell.getValue(), 'YYYY.MM.DD HH:mm:ss')}</>
        ),
      },
      {
        accessorKey: 'duration',
        header: 'Processing Time',
        Cell: ({ row }: any) => {
          const startTime = new Date(row.original.startTime);
          const endTime = new Date(row.original.endTime);

          const durationMs = differenceInMilliseconds(endTime, startTime);

          const minutes = Math.floor(durationMs / (1000 * 60));
          const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);

          const durationFormatted = format(
            new Date(0, 0, 0, 0, minutes, seconds),
            'mm:ss',
          );

          return <>{durationFormatted}</>;
        },
      },
      {
        accessorKey: 'download',
        header: 'Download',
        Cell: ({ row }: any) => (
          <IconButton
            sx={{ color: '#fff' }}
            onClick={() =>
              handleDownload(row.original.videoPath, row.original.fileName)
            }
          >
            <DownloadIcon />
          </IconButton>
        ),
      },
    ],
    [],
  );

  const rowVirtualizerInstanceRef = useRef(null);

  const handleDownload = async (filePath: string, fileName: string) => {
    const { data } = await axios.get(`/download_videos/${filePath}`, {
      responseType: 'blob',
    });
    const contentType = _.get(data, 'headers.content-type');

    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PrettyScrollbarTableWrap>
      {isFetching ? (
        <LinearProgress sx={{ backgroundColor: '#565656' }} />
      ) : (
        <div style={{ height: 4 }} />
      )}
      <MaterialReactTable
        state={{
          isLoading,
          rowSelection: selectedRows,
        }}
        initialState={{ density: 'compact' }}
        columns={columns}
        data={videoFiles}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnResizing={true}
        enableColumnVirtualization={false}
        enableGlobalFilterModes={false}
        enablePagination={false}
        enablePinning={false}
        enableRowNumbers={false}
        enableRowVirtualization={true}
        enableColumnActions={false}
        enableRowSelection={true}
        enableMultiRowSelection={true}
        getRowId={original => original.id as string}
        onRowSelectionChange={(updater: any) => {
          const newSelection = updater(selectedRows);
          setSelectedRows(newSelection);
        }}
        enableSorting={false}
        muiTablePaperProps={{ style: { background: 'transparent' } }}
        muiTableContainerProps={{
          style: {
            maxWidth: '48vw',
            maxHeight: '24vh',
          },
          sx: {
            '& .MuiTableRow-root:hover td': {
              backgroundColor: '#4A4A4A !important',
            },
          },
          ref: tableContainerRef,
        }}
        muiSelectCheckboxProps={{ size: 'small' }}
        muiTableHeadCellProps={{ align: 'left', style: { color: '#8C8C8C' } }}
        muiTableBodyCellProps={{ align: 'left', style: { color: '#8C8C8C' } }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerProps={{ overscan: 20 }}
      />
    </PrettyScrollbarTableWrap>
  );
}
