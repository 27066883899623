// @mui
import { Box, LinearProgress, Stack } from '@mui/material';
import { PopoverText, SubTitle } from '../../../components/style/CommonStyle';
import _ from 'lodash';
import { formatBytes } from '../../../utils/helpers';

export function UploadStorageBar({ storage }) {
  const resourceUsage = _.keyBy(storage?.data?.resource_usage?.storage, 'name');
  const uploadStorage = resourceUsage?.upload;
  const usedStorage = isNaN(uploadStorage?.used)
    ? '0'
    : Math.floor(uploadStorage.used).toLocaleString();
  const totalStorage = isNaN(uploadStorage?.total)
    ? '1'
    : Math.floor(uploadStorage.total).toLocaleString();

  const numericUsedStorage = parseFloat(usedStorage.replace(/,/g, ''));
  const numericTotalStorage = parseFloat(totalStorage.replace(/,/g, ''));

  const usedPercentage = (numericUsedStorage / numericTotalStorage) * 100;
  const progress = Math.floor(usedPercentage);
  let percentage = progress.toString();
  if (progress == 0) {
    percentage = usedPercentage.toFixed(2);
  }

  return (
    <Stack direction="row" textAlign="center" spacing={1} flex={1}>
      <Box sx={{width: '7%'}}>
        <SubTitle sx={{ fontSize: '0.8rem'}}>input: </SubTitle>
      </Box>
      <Box sx={{ width: '60%', marginTop: '4px !important' }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ backgroundColor: '#565656', height: '8px' }}
        />
      </Box>
      <PopoverText width="116px">
        <span>{formatBytes(numericUsedStorage, 0)}</span>/
        {formatBytes(numericTotalStorage, 0)}
      </PopoverText>
      <PopoverText>{percentage}%</PopoverText>
    </Stack>
  );
}

export function DownloadStorageBar({ storage }) {
  const resourceUsage = _.keyBy(storage?.data?.resource_usage?.storage, 'name');
  const downloadStorage = resourceUsage?.download;
  const usedStorage = isNaN(downloadStorage?.used)
    ? '0'
    : Math.floor(downloadStorage.used).toLocaleString();
  const totalStorage = isNaN(downloadStorage?.total)
    ? '1'
    : Math.floor(downloadStorage.total).toLocaleString();

  const numericUsedStorage = parseFloat(usedStorage.replace(/,/g, ''));
  const numericTotalStorage = parseFloat(totalStorage.replace(/,/g, ''));

  const usedPercentage = (numericUsedStorage / numericTotalStorage) * 100;
  const progress = Math.floor(usedPercentage);
  let percentage = progress.toString();
  if (progress == 0) {
    percentage = usedPercentage.toFixed(2);
  }
  
  return (
    <Stack direction="row" textAlign="center" spacing={1} flex={1}>
      <Box sx={{width: '7%'}}>
        <SubTitle sx={{ fontSize: '0.8rem'}}>output:</SubTitle>
      </Box>
      <Box sx={{ width: '60%', marginTop: '4px !important' }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ backgroundColor: '#565656', height: '8px' }}
        />
      </Box>
      <PopoverText width="116px">
        <span>{formatBytes(numericUsedStorage, 0)}</span>/
        {formatBytes(numericTotalStorage, 0)}
      </PopoverText>
      <PopoverText>{percentage}%</PopoverText>
    </Stack>
  );
}
