import { useMutation } from '@tanstack/react-query';
import { ApiFailModal } from '../../../components/modal/ApiResultHandler';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { StopIcon } from '../../../layouts/dashboard/nav/IconButton';
import {
  DeleteButton,
  StopButton,
} from '../../../components/style/CommonStyle';
import _ from 'lodash';
import { LocationList } from '../../../utils/enum';

export default function StopVideoEnhancementButton({
  processingVideo,
  location,
}) {
  const mutationResult = useMutation<AxiosResponse, AxiosError, any>({
    mutationFn: async payload => {
      const res = await axios.post(`/api/enqueue-video/stop/`, payload);
      return res;
    },
  });
  const { mutate, isLoading: isMutating } = mutationResult;
  const payload = {};
  return (
    <>
      {location === LocationList.QUEUING_LIST ? (
        <DeleteButton
          loading={isMutating}
          startIcon={<StopIcon />}
          disabled={_.isEmpty(processingVideo)}
          onClick={() => mutate(payload)}
        >
          STOP
        </DeleteButton>
      ) : (
        <StopButton
          loading={isMutating}
          startIcon={<StopIcon />}
          onClick={() => mutate(payload)}
          disabled={_.isEmpty(processingVideo)}
        >
          STOP
        </StopButton>
      )}
      <ApiFailModal
        apiResult={mutationResult}
        failTitle="Request failed"
        failContent="An issue occurred. Please try again."
      />
    </>
  );
}
