import { Container, Stack } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSocket } from '../../../hooks/useSocket';
import CompleteListCard from './CompleteList/CompleteListCard';
import FileListCard from './FileList/FileListCard';
import QueuingListCard from './QueuingListCard';
import RunningProcessCard from './RunningProcessCard';
// ----------------------------------------------------------------------

export default function Dashboard() {
  const [originalFile, setOriginalFile] = useState({});
  const [queueFile, setQueueFile] = useState({});
  const [completeFile, setCompleteFile] = useState({});

  const url = `${process.env.REACT_APP_WEBSOCKET_HOST}/ws/video/`;

  const { initaillyLoaded, socket } = useSocket(url, {
    ORIGINAL_VIDEO: payload => {
      setOriginalFile(payload);
    },
    QUEUED_VIDEO: payload => {
      setQueueFile(payload);
    },
    COMPLETED_VIDEO: payload => {
      setCompleteFile(payload);
    },
  });

  const [uploadingTempFiles, setUploadingTempFiles] = useState([]);

  return (
    <>
      <Helmet>
        <title> SAPEON UPSCALER </title>
      </Helmet>
      <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
        <Stack direction="row" width="100%" alignItems="stretch" height="100%">
          <RunningProcessCard queueFile={queueFile} />
          <Stack flex={1}>
            <FileListCard
              initaillyLoaded={initaillyLoaded}
              originalFile={originalFile}
              queueFile={queueFile}
              socket={socket}
              uploadingTempFiles={uploadingTempFiles}
              setUploadingTempFiles={setUploadingTempFiles}
            />
            <QueuingListCard
              initaillyLoaded={initaillyLoaded}
              queueFile={queueFile}
            />
            <CompleteListCard
              initaillyLoaded={initaillyLoaded}
              completeFile={completeFile}
              socket={socket}
            />
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
