import { useState } from 'react';
// @mui
import { Box, IconButton, Popover, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { ContentText } from '../../../components/style/CommonStyle';
import { DownIcon, UpIcon } from '../nav/IconButton';
import { ServerIcon } from './HeaderStyle';
import { DownloadStorageBar, UploadStorageBar } from './StorageBar';

export default function StoragePopover({ storage }) {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0),
            },
          }),
        }}
      >
        <DownIcon />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 3,
            pt: 2,
            px: 4,
            padding: '24px 8px 24px 26px !important',
            mt: -4.5,
            ml: 2,
            width: '44vw',
            bgcolor: '#121212',
            borderRadius: '0px',
            boxShadow: 'none',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              width: '100%',
              flex: 1,
            },
          },
        }}
      >
        <Stack direction="row" width="100%" flex={1} spacing={1}>
          <Stack flex={1} direction="row" spacing={1}>
            <Box>
              <ServerIcon />
            </Box>
            <ContentText sx={{ fontWeight: 700 }}>STORAGE</ContentText>
          </Stack>
          <Stack flex={4} spacing={1.5}>
            <UploadStorageBar storage={storage} />
            <DownloadStorageBar storage={storage} />
          </Stack>
          <IconButton onClick={handleClose} sx={{ height: '16px' }}>
            <UpIcon />
          </IconButton>
        </Stack>
      </Popover>
    </>
  );
}
