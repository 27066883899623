import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography, styled } from '@mui/material';

export const SelectImage = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  background-color: #f5f7fe;
  border-radius: 18px;
  color: #1877f2;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;
`;

export const AttachmentArea = styled(Stack)`
  border-radius: 5px;
  justify-content: space-between;
  background-color: #fff;
  padding: 6px 18px;
  width: 100%;
  min-height: 52px;
`;

export const PrettyScrollbarTableWrap = styled(Box)`
  .MuiTableContainer-root {
    /* width */
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-corner {
      background: #2a2b2c;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #8c8c8c;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #8c8c8c;
    }
  }

  table {
    border-collapse: separate;
  }
  thead {
    tr {
      box-shadow: none !important;
      th {
        border: none;
        background-color: #2a2b2c;
        font-size: 0.9rem;
        font-weight: 700;
        color: #8c8c8c;
      }
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 2px solid #2a2b2c;
        background-color: #3a3a3a;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }
`;

export const TitleText = styled(Typography)`
  font-family: Pretendard;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
`;

export const ContentText = styled(Typography)`
  font-family: Pretendard;
  font-size: 0.9rem;
  font-weight: 400;
  color: #fff;
  span {
    color: #8c8c8c;
  }
`;

export const PopoverText = styled(Typography)`
  text-overflow: none;
  font-family: Pretendard;
  font-size: 0.8rem;
  font-weight: 400;
  color: #fff;
  span {
    color: #0085ff;
  }
`;

export const SubTitle = styled(Typography)`
  font-family: Pretendard;
  font-size: 0.9rem;
  font-weight: 700;
  color: white;
`;

export const StopButton = styled(LoadingButton)`
  width: 80px;
  height: 24px;
  background-color: transparent;
  color: #ff0000;
  border-radius: 2px;
  border: 1px solid #ff0000;
  :hover {
    background-color: #ff0000;
    color: #fff;
  }
`;

export const StartButton = styled(LoadingButton)`
  width: 80px;
  height: 24px;
  background-color: transparent;
  color: #0085ff;
  border-radius: 2px;
  border: 1px solid #0085ff;
  :hover {
    background-color: #0085ff;
    color: #fff;
  }
  :disabled {
    border: none;
  }
`;

export const ActionButton = styled(LoadingButton)`
  height: 24px;
  background-color: #3a3a3a;
  color: #fff;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid transparent;

  :hover {
    background-color: #3a3a3a;
    color: #fff;
    border: 1px solid #fff;
  }
`;

export const DeleteButton = styled(LoadingButton)`
  height: 24px;
  background-color: #3a3a3a;
  color: #fff;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;

  border: 1px solid transparent;
  :hover {
    background-color: #3a3a3a;
    color: #ff0000;
    border: 1px solid #ff0000;
  }
`;

export const PrimaryButton = styled(LoadingButton)`
  border-radius: 4px;
  background: #227aef;
  color: #fff;
  width: 100px;
  height: 24px;
  font-size: 12px;
  :hover {
    background-color: #227aef;
    color: #fff;
  }
`;

export const SecondaryButton = styled(Button)`
  border-radius: 4px;
  background: #8c8c8c;
  color: #000;
  width: 100px;
  height: 24px;
  font-size: 12px;
  :hover {
    background-color: #8c8c8c;
    color: #000;
  }
`;
