import { Chip } from '@mui/material';
import _ from 'lodash';
import { StatusList, StatusListLabel } from '../../utils/enum';

export const statusTypes = _.keyBy(
  [
    {
      id: StatusList.PROCESSING,

      bgColor: '#1877F2',
      label: StatusListLabel[StatusList.PROCESSING],
    },
    {
      id: StatusList.DECODING,

      bgColor: '#4C9A2A',
      label: StatusListLabel[StatusList.DECODING],
    },
    {
      id: StatusList.PENDING,

      bgColor: '#EE5906',
      label: StatusListLabel[StatusList.PENDING],
    },
    {
      id: StatusList.READY,

      bgColor: '#8C8C8C',
      label: StatusListLabel[StatusList.READY],
    },
  ],
  'id',
);
export function StatusChips({ status }: any) {
  const label = statusTypes[status];

  return (
    <>
      {!!status && (
        <Chip
          sx={{
            background: label?.bgColor,
            color: '#fff',
            height: '24px',
            fontSize: '0.8rem',
          }}
          label={label?.label}
        />
      )}
    </>
  );
}
