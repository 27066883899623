import axios from 'axios';
import _ from 'lodash';
import { useState } from 'react';
import { ActionButton } from '../../../../components/style/CommonStyle';
import { DownloadIcon } from '../../../../layouts/dashboard/nav/IconButton';

export function DownloadVideoButton({ selectTable, fileList }) {
  const [downloading, setDownloading] = useState(false);

  const keyById = _.keyBy(fileList, 'id');
  const selectedFileList = _.pickBy(keyById, (value, key) => selectTable[key]);
  const selectedRows = _.values(selectedFileList);

  const handleDownload = async () => {
    try {
      setDownloading(true);
      for (let row of selectedRows) {
        const filePath = row?.result_video_path;
        const fileName = row?.name;

        const { data } = await axios.get(`/download_videos/${filePath}`, {
          responseType: 'blob',
        });
        const contentType = _.get(data, 'headers.content-type');

        const blob = new Blob([data], { type: contentType });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } finally {
      setDownloading(false);
    }
  };

  return (
    <>
      <ActionButton
        onClick={handleDownload}
        startIcon={<DownloadIcon />}
        disabled={_.isEmpty(selectTable)}
        loading={downloading}
      >
        DOWNLOAD
      </ActionButton>
    </>
  );
}
