import { Typography, styled } from '@mui/material';

export const HeaderInfo = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 700;
  text-align: left;
  color: #fff;
`;

export function ServerIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_34_259)">
        <path
          d="M10 1H2C1.44772 1 1 1.44772 1 2V4C1 4.55228 1.44772 5 2 5H10C10.5523 5 11 4.55228 11 4V2C11 1.44772 10.5523 1 10 1Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 7H2C1.44772 7 1 7.44772 1 8V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V8C11 7.44772 10.5523 7 10 7Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 3H3.00542"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 9H3.00542"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_259">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ClockIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_34_265)">
        <path
          d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 3V6L8 7"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_265">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
