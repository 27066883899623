import { Stack } from '@mui/material';
import { WarningIcon } from '../../../layouts/dashboard/nav/IconButton';
import { ContentText } from '../../../components/style/CommonStyle';

export function EmptyDiv({ content = 'List is empty' }) {
  return (
    <Stack
      direction="row"
      spacing={1}
      bgcolor="#3A3A3A"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <WarningIcon />
      <ContentText>{content}</ContentText>
    </Stack>
  );
}
