import { CardContent, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { PrimaryButton } from '../style/CommonStyle';
import { CenterModal, ModalCard } from './Modal';

export function OkModal({
  on,
  title = '',
  loading = false,
  confirmText = 'OK',
  children = <></>,
  onClose,
}: {
  on: boolean;
  title?: string | React.ReactNode;
  loading?: boolean;
  confirmText?: string | React.ReactNode;
  children?: string | React.ReactNode;
  onClose: any;
}) {
  return (
    <>
      <CenterModal open={on} onClose={onClose}>
        <ModalCard>
          <CardContent>
            <Stack spacing={3}>
              <Stack alignItems="center">
                <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
              </Stack>
              <Stack alignItems="center">{children}</Stack>
              <Divider />
              <Stack direction="row" justifyContent="center" spacing={1}>
                <PrimaryButton loading={loading || false} onClick={onClose}>
                  {confirmText}
                </PrimaryButton>
              </Stack>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}
