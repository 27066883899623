import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { ActionButton } from '../../../../components/style/CommonStyle';
import { OrderIcon } from '../../../../layouts/dashboard/nav/IconButton';
import { ApiFailModal } from '../../../../components/modal/ApiResultHandler';

export function EnqueueButton({ selectTable, isProcessing, setSelectedRows, disable }) {
  const isNotSelected = _.isEmpty(selectTable) || disable ;
  const mutationResult = useMutation<AxiosResponse, AxiosError, any>({
    mutationFn: async payload => {
      const res = await axios.post(`/api/enqueue-video/`, payload);
      return res;
    },
    onSuccess() {
      setSelectedRows([]);
    },
  });
  const { mutate, isLoading: isMutating } = mutationResult;
  const vieoIdesArray = _.keys(selectTable);

  const payload = {
    video_ids: vieoIdesArray,
  };

  return (
    <>
      <ActionButton
        startIcon={<OrderIcon />}
        loading={isMutating}
        disabled={isNotSelected || isProcessing}
        onClick={() => mutate(payload)}
      >
        ENQUEUE
      </ActionButton>
      <ApiFailModal
        apiResult={mutationResult}
        failTitle="Request failed"
        failContent="An issue occurred. Please try again."
        ErrorCodeTable={{
          VIDEO_ALREADY_IN_QUEUE: { content: 'video is already in queue' },
        }}
      />
    </>
  );
}
