import { IconButton, styled, TextField } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { SearchIcon } from '../../layouts/dashboard/nav/IconButton';

export const SearchBar = styled(TextField)`
  flex: 1;
  width: 160px;

  background-color: #3a3a3a;
  fieldset {
    border-radius: 2px;
    border: none;
  }
  button {
    padding: 2px;
  }
  input {
    padding: 2px;
  }
`;

export function DetailedSearch({
  placeholder = 'SEARCH FILE',
  onKeyDown = _.noop,
  onBlur = _.noop,
  onClick = _.noop,
}) {
  const [search, setSearch] = useState('');

  return (
    <SearchBar
      fullWidth
      size="small"
      sx={{ height: '24px' }}
      InputProps={{
        style: { color: '#fff', fontSize: '0.9rem' },
        startAdornment: (
          <IconButton onClick={e => onClick(e, search)}>
            <SearchIcon />
          </IconButton>
        ),
      }}
      placeholder={placeholder}
      onKeyDown={e => onKeyDown(e, search)}
      onChange={e => {
        setSearch(e.target.value);
      }}
      onBlur={e => onBlur(e, search)}
    />
  );
}
