// @mui
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { ContentText } from '../../../components/style/CommonStyle';
import { useSocket } from '../../../hooks/useSocket';
import { headerDate } from '../../../utils/formatTime';
import StoragePopover from './AccountPopover';
import { ClockIcon, ServerIcon } from './HeaderStyle';
import { UploadStorageBar } from './StorageBar';
// ----------------------------------------------------------------------

const HEADER_MOBILE = 48;

const HEADER_DESKTOP = 48;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 3),
  },
  background: '#2A2B2C',
  borderBottom: '2px solid #1E1E1E',
}));

// ----------------------------------------------------------------------

export default function Header() {
  const [storage, setStorage] = useState({});

  const url = `${process.env.REACT_APP_WEBSOCKET_HOST}/ws/video/`;

  useSocket(url, {
    USAGE_STATISTICS: payload => {
      setStorage(payload);
    },
  });

  return (
    <Stack direction="column">
      <StyledRoot>
        <StyledToolbar>
          <Box>
            <img
              src={require('../../../app/static/images/sapeon_logo.png')}
              alt="logo"
            />
          </Box>
          <Stack direction="row" alignItems="center" spacing={1} ml={5}>
            <ClockIcon />
            <Clock />
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={1} flex={1}>
            <ServerIcon />
            <ContentText sx={{ fontWeight: 700, marginTop: '2px !important' }}>
              STORAGE
            </ContentText>
            <UploadStorageBar storage={storage} />
            <StoragePopover storage={storage} />
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </Stack>
  );
}

function Clock() {
  const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(prevTime => prevTime + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = new Date(currentTime * 1000).toISOString();

  return (
    <Typography sx={{ fontSize: '0.8rem', pt: '2px' }}>
      {headerDate(formattedTime, 'yyyy.MM.DD HH:mm:ss')}
    </Typography>
  );
}
