import {
  CardContent,
  ClickAwayListener,
  Divider,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { CenterModal, ModalCard } from '../../../../components/modal/Modal';
import {
  ActionButton,
  PrimaryButton,
} from '../../../../components/style/CommonStyle';
import { UploadIcon } from '../../../../layouts/dashboard/nav/IconButton';

export function VideoUploadButton({
  uploadingTempFiles,
  setUploadingTempFiles,
}) {
  const fileInputRef = useRef<any>();
  const folderInputRef = useRef<any>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [duplicateFileName, setDuplicateFileName] = useState<string>('');
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  useDeepCompareEffect(() => {
    function popUpExit() {
      return 'You want to leave this page?';
    }
    if (!_.isEmpty(uploadingTempFiles)) {
      window.onbeforeunload = popUpExit;
    } else {
      window.onbeforeunload = null;
    }
  }, [uploadingTempFiles]);

  const onSelect = async e => {
    const inputElem = e.target;
    try {
      if (!inputElem || inputElem.files.length === 0) {
        return;
      }

      const files: any[] = [];
      for (let i = 0; i < inputElem.files.length; i += 1) {
        const file = inputElem.files[i];

        files.push({
          folder_name: file.webkitRelativePath.slice(
            0,
            file.webkitRelativePath.length - (file.name.length + 1),
          ),
          file_name: file.name,
          type: file.type,
        });
      }
      const { data } = await axios.post('/api/video/duplicity', {
        data: files,
      });

      const duplicateVideos = data.videos;
      if (!_.isEmpty(duplicateVideos)) {
        const msg = duplicateVideos
          .map(it => `${it.path}/${it.name}`)
          .join(', ');
        setDuplicateFileName(msg);
        setModalOpen(true);
        return;
      }

      const extensions = ['mp4', 'avi', 'mov', 'mkv', 'wmv', 'flv', 'mxf'];
      const uploadingFiles = files
        .map((it, i) => ({
          id: `${Math.random()}`,
          folderName: it.folder_name,
          fileName: it.file_name,
          type: it.type,
          size: '-',
          format: '-',
          length: '-',
          uploading: true,
          index: i,
        }))
        .filter(
          f => !_.isEmpty(extensions.filter(ext => {
            if (f.type === undefined || f.type === "") {
              return _.endsWith(f.fileName.split('.').pop(), ext)
            } else {
              return _.endsWith(f.type, ext)
            }
          })),
        );

      setUploadingTempFiles(uploadingFiles);
      const removedFiles: any[] = [];
      for (let uploadingFile of uploadingFiles) {
        // Upload one by one
        const file = inputElem.files[uploadingFile.index];
        await uploadVideo(file);
        removedFiles.push(uploadingFile);

        const newUploadingFiles = _.differenceBy(
          uploadingFiles,
          removedFiles,
          'id',
        );

        setUploadingTempFiles(newUploadingFiles);
      }
    } finally {
      _.set(inputElem, 'value', '');
    }
  };

  const uploadVideo = async file => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'path',
      file.webkitRelativePath.slice(
        0,
        file.webkitRelativePath.length - (file.name.length + 1),
      ),
    );

    await axios
      .post('/api/video/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => {
        console.error(`Error uploading file ${file.name}:`, error);
        setModalOpen(true);
        setDuplicateFileName(`${file.name}`);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
    setDuplicateFileName('');
  };

  return (
    <>
      <DirectoryInput
        folderInputRef={folderInputRef}
        onChange={onSelect}
        webkitdirectory=""
      />

      <input
        ref={fileInputRef}
        accept="video/mp4,video/x-m4v,.mxf,video/*"
        type="file"
        multiple={true}
        onChange={onSelect}
        style={{ display: 'none' }}
      />

      <ClickAwayListener
        onClickAway={() => {
          if (openedPopover) {
            setOpenedPopover(false);
          }
        }}
      >
        <ActionButton
          startIcon={<UploadIcon />}
          loading={!_.isEmpty(uploadingTempFiles)}
          aria-haspopup="true"
          onClick={e => {
            setOpenedPopover(prev => !prev);
          }}
          ref={popoverAnchor}
        >
          UPLOAD
        </ActionButton>
      </ClickAwayListener>
      <Popover
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        sx={{
          // pointerEvents: 'none',
          zIndex: 100000,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 0.5,
            backgroundColor: '#3a3a3a',
            borderRadius: 0,
            boxShadow: 'none',
            color: '#fff',
            '& .MuiMenuItem-root': {
              typography: 'body2',
            },
            zIndex: 1000,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            folderInputRef.current.click();
          }}
        >
          Folder Upload
        </MenuItem>
        <MenuItem
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          File Upload
        </MenuItem>
      </Popover>

      <CenterModal open={isModalOpen} onClose={closeModal}>
        <ModalCard>
          <CardContent>
            <Stack spacing={3}>
              <Stack alignItems="center">
                <Typography sx={{ fontWeight: 700 }}>
                  Duplicate File Detected
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography>{`The ${duplicateFileName} already exist${
                  duplicateFileName.split(', ').length === 1 ? 's' : ''
                }.`}</Typography>
              </Stack>
              <Divider />
              <Stack direction="row" justifyContent="center" spacing={1}>
                <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
              </Stack>
            </Stack>
          </CardContent>
        </ModalCard>
      </CenterModal>
    </>
  );
}

function DirectoryInput({ folderInputRef, ...props }) {
  return (
    <input
      ref={folderInputRef}
      accept="video/mp4,video/x-m4v,.mxf,video/*"
      type="file"
      // accept="*"
      multiple={true}
      style={{ display: 'none' }}
      {...props}
    />
  );
}
