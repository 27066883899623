import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { differenceInSeconds } from 'date-fns';
import _ from 'lodash';
import MaterialReactTable from 'material-react-table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ApiFailModal } from '../../../components/modal/ApiResultHandler';
import { StatusChips } from '../../../components/select/StatusChips';
import {
  DeleteButton,
  PrettyScrollbarTableWrap,
  SubTitle,
  TitleText,
} from '../../../components/style/CommonStyle';
import { DeleteIcon } from '../../../layouts/dashboard/nav/IconButton';
import { StatusList } from '../../../utils/enum';
import { fDate, formatTime } from '../../../utils/formatTime';
import { EmptyDiv } from './EmptyDiv';

export default function QueuingListCard({ queueFile, initaillyLoaded }) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [passedSeconds, setPassedSeconds] = useState(0);
  const fileList = _.get(queueFile, 'data') || [];
  const totalDBRowCount = fileList.length;
  const processingVideo = _.filter(fileList, f =>
    [StatusList.PROCESSING, StatusList.DECODING, StatusList.PENDING].includes(f.status),
  );
  const processingFileInfo = processingVideo[0];

  useEffect(() => {
    if (!_.isEmpty(processingFileInfo)) {
      setPassedSeconds(0);
      if (processingFileInfo.start_time !== null) {
        const intervalId = setInterval(() => {
          const now = new Date()
          const utc = now.getTime() - (now.getTimezoneOffset() * 60 * 1000)
          const passedSeconds = differenceInSeconds(
            new Date(utc),
            new Date(processingFileInfo.start_time),
          );
          setPassedSeconds(passedSeconds);
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line
  }, [processingFileInfo?.start_time]);

  const videoFiles = useMemo(() => {
    const fileList = _.get(queueFile, 'data') || [];

    return fileList.map(item => ({
      id: _.get(item, 'id'),
      status: _.get(item, 'status', '-'),
      fileName: _.get(item, 'video.name', '-'),
      time: _.get(item, 'start_time', '-'),
      duration: _.get(item, 'video.duration', '-'),
      processingTime:
        item.status === StatusList.PROCESSING ? formatTime(passedSeconds) : '',
    }));
  }, [passedSeconds, queueFile]);

  return (
    <Stack flex={1} p={3} borderBottom="2px solid #1E1E1E" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TitleText>QUEUING LIST</TitleText>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <DequeueButton
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            processingVideo={processingVideo}
          />
        </Stack>
      </Stack>
      <Stack height="100%">
        {totalDBRowCount === 0 ? (
          <EmptyDiv />
        ) : (
          <>
            <QueuingVideoFileTable
              videoFiles={videoFiles}
              isFetching={!initaillyLoaded}
              isLoading={!initaillyLoaded}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}

function QueuingVideoFileTable({
  videoFiles,
  isFetching,
  isLoading,
  selectedRows,
  setSelectedRows,
}: any) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }: any) => <StatusChips status={cell.getValue()} />,
      },
      {
        accessorKey: 'fileName',
        header: 'File Name',
        Cell: ({ cell }: any) => (
          <Typography sx={{ color: '#fff', fontSize: '0.9rem' }}>
            {cell.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'time',
        header: 'Start Time',
        Cell: ({ cell }) => (
          <>
            {_.isEmpty(cell.getValue()) ? (
              <></>
            ) : (
              <>
                <SubTitle>START</SubTitle>
                <Box width="0.8rem" />
                {fDate(cell.getValue(), 'yyyy.MM.DD HH:mm:ss') || '-'}
              </>
            )}
          </>
        ),
      },
      {
        accessorKey: 'processingTime',
        header: 'Processing Time',
        Cell: ({ cell }: any) => <>{cell.getValue()}</>,
      },
    ],
    [],
  );

  const rowVirtualizerInstanceRef = useRef(null);

  return (
    <PrettyScrollbarTableWrap>
      {isFetching ? (
        <LinearProgress sx={{ backgroundColor: '#565656' }} />
      ) : (
        <div style={{ height: 4 }} />
      )}
      <MaterialReactTable
        state={{
          isLoading,
          rowSelection: selectedRows,
        }}
        initialState={{ density: 'compact' }}
        columns={columns}
        data={videoFiles}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnResizing={true}
        enableColumnVirtualization={false}
        enableGlobalFilterModes={false}
        enablePagination={false}
        enablePinning={false}
        enableRowNumbers={false}
        enableRowVirtualization={true}
        enableColumnActions={false}
        enableRowSelection={true}
        enableMultiRowSelection={true}
        getRowId={original => original.id as string}
        onRowSelectionChange={(updater: any) => {
          const newSelection = updater(selectedRows);
          setSelectedRows(newSelection);
        }}
        enableSorting={false}
        muiTablePaperProps={{ style: { background: 'transparent' } }}
        muiTableContainerProps={{
          style: {
            maxWidth: '48vw',
            maxHeight: '24vh',
          },
          sx: {
            '& .MuiTableRow-root:hover td': {
              backgroundColor: '#4A4A4A !important',
            },
          },
          ref: tableContainerRef,
        }}
        muiSelectCheckboxProps={{ size: 'small' }}
        muiTableHeadCellProps={{ align: 'left', style: { color: '#8C8C8C' } }}
        muiTableBodyCellProps={{ align: 'left', style: { color: '#8C8C8C' } }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerProps={{ overscan: 5 }}
      />
    </PrettyScrollbarTableWrap>
  );
}

function DequeueButton({ selectedRows, processingVideo, setSelectedRows }) {
  const mutationResult = useMutation<AxiosResponse, AxiosError, any>({
    mutationFn: async payload => {
      const res = await axios.delete(`/api/enqueue-video/`, {
        data: payload,
      });
      return res;
    },
    onSuccess() {
      setSelectedRows([]);
    },
  });
  const { mutate, isLoading: isMutating } = mutationResult;
  const vieoIdesArray = _.keys(selectedRows);

  const payload = {
    video_ids: vieoIdesArray,
  };

  return (
    <>
      <DeleteButton
        loading={isMutating}
        startIcon={<DeleteIcon />}
        disabled={_.isEmpty(selectedRows) || !_.isEmpty(processingVideo)}
        onClick={() => mutate(payload)}
      >
        DEQUEUE
      </DeleteButton>
      <ApiFailModal
        apiResult={mutationResult}
        failTitle="Request failed"
        failContent="An issue occurred. Please try again."
      />
    </>
  );
}
