export enum StatusList {
  PROCESSING = 'PROCESSING',
  DECODING = 'DECODING',
  PENDING = 'PENDING',
  READY = 'READY',
}
export const StatusListLabel = {
  [StatusList.PROCESSING]: 'PROCESSING',
  [StatusList.DECODING]: 'DECODING',
  [StatusList.PENDING]: 'PENDING',
  [StatusList.READY]: 'READY',
};

export enum LocationList {
  QUEUING_LIST = 'QUEUING_LIST',
  RUNNING_PROCESS = 'RUNNING_PROCESS',
}

export enum ProcessingStatusList {
  PROCESSING = 'PROCESSING',
  DECODING = 'DECODING',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export const ProcessingStatusListLabel = {
  [ProcessingStatusList.PROCESSING]: 'PROCESSING',
  [ProcessingStatusList.DECODING]: 'DECODING',
  [ProcessingStatusList.PENDING]: 'PENDING',
  [ProcessingStatusList.COMPLETED]: 'COMPLETED',
};
