import _ from 'lodash';
import { useEffect, useState } from 'react';

export function useSocket(
  url: String,
  handlers: {
    [key: string]: (...args) => any;
  },
) {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const [initaillyLoaded, setInitallyLoaded] = useState(false);

  useEffect(() => {
    let created: any = null;
    if (!connected) {
      connectSocket();
    }

    async function connectSocket() {
      const socketUrl = `${url}`;
      const protocol = _.startsWith(window.location.protocol, 'https')
         ? 'wss'
         : 'ws';
      const socket = new WebSocket(`${protocol}://${socketUrl}`);

      setSocket(socket);

      socket.onmessage = event => {
        setInitallyLoaded(true);
        setConnected(true);

        try {
          const { type, ...payload } = JSON.parse(event.data);

          const handler = _.get(handlers, type);
          if (type === 'revalidate') {
          } else if (typeof handler === 'function') {
            handler(payload);
          }
        } catch (err) {
          console.error(err);
        }
      };

      socket.onopen = () => {
        setConnected(true);
      };

      socket.onclose = () => {
        setConnected(false);
      };
      created = socket;
    }
    return () => {
      if (created) {
        // created.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, connected]);
  return { socket, connected, initaillyLoaded };
}
