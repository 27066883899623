import {
  CardContent,
  CircularProgress,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import { useMemo, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { DetailedSearch } from '../../../../components/search-input/DetailedSearchBar';
import {
  PrettyScrollbarTableWrap,
  TitleText,
  PrimaryButton,
} from '../../../../components/style/CommonStyle';
import { CenterModal, ModalCard } from '../../../../components/modal/Modal';
import { StatusList } from '../../../../utils/enum';
import { EmptyDiv } from '../EmptyDiv';
import { EnqueueButton } from './EnqueueButton';
import { RemoveOriginalVideoButton } from './RemoveOriginalVideoButton';
import { VideoUploadButton } from './VideoUploadButton';
import { formatBytes } from '../../../../utils/helpers';
import { formatTime } from '../../../../utils/formatTime';

export default function FileListCard({
  initaillyLoaded,
  originalFile,
  queueFile,
  socket,
  uploadingTempFiles,
  setUploadingTempFiles,
}) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const videoFiles = useMemo(() => {
    const fileList = _.get(originalFile, 'data') || [];
    const serverSideList = fileList.map(item => ({
      id: _.get(item, 'id'),
      folderName: _.get(item, 'path', '-'),
      fileName: _.get(item, 'name', '-'),
      size: formatBytes(_.get(item, 'filesize', 0)),
      format: _.get(item, 'format', '-'),
      length: _.get(item, 'duration')
        ? formatTime(_.get(item, 'duration'))
        : '-',
    }));
    return serverSideList;
  }, [originalFile]);

  const fileList = _.get(originalFile, 'data') || [];
  const totalDBRowCount = fileList.length;

  const queueFileList = _.get(queueFile, 'data') || [];
  const processingVideo = _.filter(queueFileList, {
    status: StatusList.PROCESSING,
  });
  const isProcessing = !_.isEmpty(processingVideo);

  function refreshWithSearchKeyword(search) {
    socket?.send(
      JSON.stringify({
        message: 'ORIGINAL_VIDEO_LIST',
        search_keyword: search,
      }),
    );
  }
  
  // If change need to change maxium number of queuling list, below change variable
  const MAXIMUM_NUMBER_OF_QUEUE_FILE = 10

  // Close Alert modal
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  const [disableEnqueueButton, setDisableEnqueueButton ] = useState(false) 
  useDeepCompareEffect(()=> {
    if (Object.keys(selectedRows).length > MAXIMUM_NUMBER_OF_QUEUE_FILE) {
      setDisableEnqueueButton(true)
      setModalOpen(true)
    } else {
      setDisableEnqueueButton(false)
    }
  }, [selectedRows])

  return (
    <Stack flex={1} p={3} borderBottom="2px solid #1E1E1E" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TitleText>FILE LIST</TitleText>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <DetailedSearch
            onKeyDown={(e, search) => {
              if (e.key === 'Enter') {
                refreshWithSearchKeyword(search);
              }
            }}
            onClick={(e, search) => {
              refreshWithSearchKeyword(search);
            }}
          />
          <VideoUploadButton
            uploadingTempFiles={uploadingTempFiles}
            setUploadingTempFiles={setUploadingTempFiles}
          />
          <EnqueueButton
            setSelectedRows={setSelectedRows}
            selectTable={selectedRows}
            isProcessing={isProcessing}
            disable={disableEnqueueButton}
          />
          <RemoveOriginalVideoButton
            setSelectedRows={setSelectedRows}
            selectTable={selectedRows}
            isProcessing={isProcessing}
          />
        </Stack>
      </Stack>
      <Stack height="100%">
        {totalDBRowCount === 0 && _.isEmpty(uploadingTempFiles) ? (
          <EmptyDiv />
        ) : (
          <>
            <OriginalVideoFileTable
              videoFiles={[...uploadingTempFiles, ...videoFiles]}
              isFetching={!initaillyLoaded}
              isLoading={!initaillyLoaded}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </>
        )}
      </Stack>
      <CenterModal open={isModalOpen} onClose={closeModal}>
        <ModalCard>
          <CardContent>
            <Stack spacing={3}>
              <Stack alignItems="center">
                <Typography sx={{ fontWeight: 700 }}>
                  Exceed number of selection video file
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography>Available for enqueue <b style={{color:"red"}}>10 input videos</b> into QUEUING LIST</Typography>
              </Stack>
              <Divider />
              <Stack direction="row" justifyContent="center" spacing={1}>
                <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
              </Stack>
            </Stack>
          </CardContent>
        </ModalCard>
    </CenterModal>
    </Stack>
  );
}

function OriginalVideoFileTable({
  videoFiles,
  isFetching,
  isLoading,
  selectedRows,
  setSelectedRows,
}: any) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(
    () => [
      { accessorKey: 'folderName', header: 'Folder Name' },
      {
        accessorKey: 'fileName',
        header: 'File Name',
        Cell: ({ cell, row, ...rest }: any) => {
          const original = row.original;
          return (
            <>
              {original?.uploading && (
                <CircularProgress
                  style={{ width: 17, height: 17, marginRight: 8 }}
                />
              )}
              <Typography sx={{ color: '#fff', fontSize: '0.9rem' }}>
                {cell.getValue()}
              </Typography>
            </>
          );
        },
      },
      {
        accessorKey: 'size',
        header: 'Size',
        Cell: ({ cell }: any) => <>{cell.getValue()}</>,
      },
      { accessorKey: 'format', header: 'Format' },
      { accessorKey: 'length', header: 'Duration' },
    ],
    [],
  );

  const rowVirtualizerInstanceRef = useRef(null);

  return (
    <PrettyScrollbarTableWrap>
      {isFetching ? (
        <LinearProgress sx={{ backgroundColor: '#565656' }} />
      ) : (
        <div style={{ height: 4 }} />
      )}
      <MaterialReactTable
        state={{
          isLoading,
          rowSelection: selectedRows,
        }}
        initialState={{ density: 'compact' }}
        columns={columns}
        data={videoFiles}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnResizing={true}
        enableColumnVirtualization={false}
        enableGlobalFilterModes={false}
        enablePagination={false}
        enablePinning={false}
        enableRowNumbers={false}
        enableRowVirtualization={true}
        enableColumnActions={false}
        enableRowSelection={true}
        enableMultiRowSelection={true}
        getRowId={original => original.id as string}
        onRowSelectionChange={(updater: any) => {
          const newSelection = updater(selectedRows);
          setSelectedRows(newSelection);
        }}
        enableSorting={false}
        muiTablePaperProps={{ style: { background: 'transparent' } }}
        muiTableContainerProps={{
          style: {
            maxWidth: '48vw',
            maxHeight: '24vh',
          },
          sx: {
            '& .MuiTableRow-root:hover td': {
              backgroundColor: '#4A4A4A !important',
            },
          },
          ref: tableContainerRef,
        }}
        muiSelectCheckboxProps={({ row }) => {
          return { size: 'small', disabled: !!row.original?.uploading };
        }}
        muiTableHeadCellProps={{ align: 'left', style: { color: '#8C8C8C' } }}
        muiTableBodyCellProps={{ align: 'left', style: { color: '#8C8C8C' } }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerProps={{ overscan: 5 }}
      />
    </PrettyScrollbarTableWrap>
  );
}
