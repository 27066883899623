import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Stack, styled, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { ModalText, useModalToggle } from './Modal';
import { OkModal } from './OkModal';
import { produce } from 'immer';

export function defaults<T>(left: T, right: T): T {
  const copy = produce(left, (draft: any) => {
    return _.defaults(draft, right);
  });

  return copy;
}

export function getApiError(apiResult: any, ErrorCodeTable = {}) {
  const errorCode = _.get(apiResult, 'error.response.data.code');
  const errorMsg = _.get(apiResult, 'error.response.data.msg');
  const error = _.get(ErrorCodeTable, errorMsg);
  const loading = _.get(apiResult, 'loading');
  const errored = _.get(apiResult, 'error.response.status') >= 400 && !loading;

  const title = errored ? _.get(error, `title`) : '';
  const content = errored ? _.get(error, `content`) : '';
  return {
    errored,
    title,
    content,
    errorCode,
    errorMsg,
  };
}

export function ApiResultModal({
  apiResult,
  onSuccessClose = _.noop,
  successTitle = '',
  successContent = '',
  successConfirmText,
  onFailClose = _.noop,
  failTitle = '',
  failContent = '',
  failConfirmText,
  ErrorCodeTable = {},
  successCode = 200,
}: {
  apiResult: any;
  onSuccessClose?: Function;
  successTitle?: string | React.ReactNode;
  successContent?: string | React.ReactNode;
  successConfirmText?: string | React.ReactNode;
  onFailClose?: Function;
  failTitle?: string | React.ReactNode;
  failContent?: string | React.ReactNode;
  failConfirmText?: string | React.ReactNode;
  ErrorCodeTable?: {
    [key: string]: {
      title?: string | React.ReactNode;
      content?: string | React.ReactNode;
    };
  };
  successCode?: number;
}) {
  const { loading } = apiResult;

  const successStatus = _.get(apiResult, 'response.status');
  const didSucceed = successCode === successStatus;

  const errorStatus = _.get(apiResult, 'error.response.status');
  const errorCode = _.get(apiResult, 'error.response.data.error');
  const isAuthError = errorCode === 'JWT_NOT_VERIFIED';
  const apiError = getApiError(apiResult, ErrorCodeTable);

  const didFail = errorStatus >= 400;
  const fTitle = apiError?.title || failTitle;
  const fContent = apiError?.content || failContent;

  const { open, close, on } = useModalToggle(() => {
    didSucceed && onSuccessClose();
    didFail && onFailClose();
  });
  useUpdateEffect(() => {
    if ((didFail || didSucceed) && !loading && !isAuthError) {
      open();
    }
  }, [didSucceed, didFail, loading, isAuthError]);

  return (
    <OkModal
      on={on}
      onClose={close}
      title={didSucceed ? successTitle : didFail ? fTitle : ''}
      confirmText={
        didSucceed ? successConfirmText : didFail ? failConfirmText : ''
      }
    >
      <>
        {didSucceed ? (
          <Stack direction="column" alignItems="center">
            <ModalText>{successContent}</ModalText>
          </Stack>
        ) : undefined}
        {didFail ? (
          <Stack direction="column" alignItems="center" spacing={3}>
            <ModalText>{fContent}</ModalText>
            {''}
            {JSON.stringify(
              _.get(apiResult, 'error.response.data.msg') || '',
              null,
              2,
            )}
          </Stack>
        ) : undefined}
      </>
    </OkModal>
  );
}

export function useSuccessRedirect(
  apiResult: any,
  redirectTo: any,
  option?: any,
) {
  const [{ onSuccess, successCode }] = useState(
    defaults(option, {
      onSuccess: _.noop,
      successCode: 200,
    }),
  );
  const successStatus = _.get(apiResult, 'response.status');
  const didSucceed = successCode === successStatus;
  const loading = apiResult.loading;

  const navigate = useNavigate();

  useUpdateEffect(() => {
    if (didSucceed && !loading) {
      navigate(redirectTo);
      onSuccess(apiResult);
    }
  }, [didSucceed, loading, onSuccess]);
}

export function useFailRedirect(apiResult: any, redirectTo: any, option?: any) {
  const [{ onFail }] = useState(defaults(option, { onFail: _.noop }));
  const errorStatus = _.get(apiResult, 'error.response.status');
  const loading = apiResult.loading;
  const didFail = errorStatus >= 400;

  const navigate = useNavigate();

  useUpdateEffect(() => {
    if (didFail && !loading) {
      navigate(redirectTo);
      onFail(apiResult);
    }
  }, [didFail, loading, onFail]);
}

const SuccessIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  width: 56,
  height: 56,
  marginBottom: 16,
}));

export function ApiSuccessModal({
  apiResult,
  onClose = _.noop,
  successTitle = '',
  successContent = '',
  successCode = 200,
}: any) {
  const successStatus = _.get(apiResult, 'response.status');
  const didSucceed = successCode === successStatus;
  const { loading } = apiResult;
  const { open, close, on } = useModalToggle(onClose);
  useUpdateEffect(() => {
    if (didSucceed && !loading) {
      open();
    }
  }, [didSucceed, loading]);

  return (
    <OkModal on={on} onClose={close} title={successTitle}>
      <Stack direction="column" alignItems="center" sx={{ minWidth: 640 }}>
        <SuccessIcon />
        <Typography>{successContent}</Typography>
      </Stack>
    </OkModal>
  );
}

export function ApiFailModal({
  apiResult,
  onClose = _.noop,
  failTitle = 'Request failed',
  failContent = '',
  ErrorCodeTable = {},
}: any) {
  const errorStatus = _.get(apiResult, 'error.response.status');
  const { loading } = apiResult;

  const didFail = errorStatus >= 400;
  const errorCode = _.get(apiResult, 'error.response.data.code');
  const isAuthError = errorCode === 'JWT_NOT_VERIFIED';
  const apiError = getApiError(apiResult, ErrorCodeTable);
  const title = apiError?.title || failTitle;
  const content = apiError?.content || failContent;

  const { open, close, on } = useModalToggle(onClose);
  useUpdateEffect(() => {
    if (didFail && !loading && !isAuthError) {
      open();
    }
  }, [didFail, loading, isAuthError]);

  return (
    <OkModal on={on} onClose={close} title={title}>
      <Typography>{content}</Typography>
    </OkModal>
  );
}

const SuccessText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '0.75rem',
  padding: '4px 0 0 12px',
}));

export function ApiSuccessMsg({
  apiResult,
  msg,
  successCode = 200,
  ...rest
}: any) {
  const status = _.get(apiResult, 'response.status');

  return status === successCode ? (
    <SuccessText {...rest}>{msg}</SuccessText>
  ) : null;
}

const FailText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: '0.75rem',
  padding: '4px 0 0 12px',
}));

export function ApiFailMsg({ apiResult, ErrorCodeTable = {}, ...rest }: any) {
  const status = _.get(apiResult, 'response.status');
  const didFail = status >= 400;
  const msg = _.get(
    ErrorCodeTable,
    _.get(apiResult, 'error.response.data.msg'),
  );
  return didFail ? <FailText {...rest}>{msg}</FailText> : null;
}
