import { Card, Modal as MtModal, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useCallback } from 'react';
import { useToggle } from 'react-use';
import { styled } from '@mui/material/styles';

export const ModalText = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

export const ModalHeader = styled(Stack)`
  padding: 20px;
  border-bottom: 1px solid #e6e0e9;
  flex-direction: row;
  align-items: center;
`;

export const ModalCard = styled(Card)`
  min-width: 300px;
  background-color: #2a2b2c;
  color: #fff;
  box-shadow: none;
  border: 1px solid #505050;
`;

export const CenterModal = styled<any>(MtModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  span:focus {
    outline: 0;
  }

  ${({ bound }) =>
    bound
      ? `
  & > div > * {
    max-height: calc(100vh - 120px);
    overflow-y: scroll;
  }
  
  `
      : ''}
`;

export function useModalToggle(onClose = _.noop) {
  const [on, toggle] = useToggle(false);

  const open = useCallback(() => {
    toggle(true);
  }, [toggle]);

  const close = useCallback(() => {
    toggle(false);
    onClose();
  }, [toggle, onClose]);

  return { on, open, close, toggle };
}
